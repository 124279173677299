declare global {
    interface Window {
        jivo_api?: {
            setWidgjivo_destroy: () => void;
        };
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    const layoutJivoShouldHide = ['auth', 'chat', 'pricing']; // layouts onde o Jivo deve ser escondido
    const route = useRoute();

    const checkVisibility = () => {
        const layout = route.meta.layout || 'default';
        const shouldHide = layoutJivoShouldHide.includes(layout);

        if (window.jivo_api) {            
            if (shouldHide){
                window.jivo_api.setWidgjivo_destroy()
            }
        } else {
            setTimeout(checkVisibility, 300);
        }
    };

    watch(() => route.meta.layout, () => {
        checkVisibility();
    });

    // Executa na carga inicial também
    nuxtApp.hook('page:finish', () => {
        checkVisibility();
    });
});
