
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexvw_LzcSJtvzvAn_45SelXzqu_kF7hXiHpQP6vheBc6EawMeta } from "/tmp/8dd6d687f2027df/pages/index.vue?macro=true";
import { default as noroleXFFF0QKBKoePbvX7dxBMX_ewdtEW0d4El9WQ_pakhhoMeta } from "/tmp/8dd6d687f2027df/pages/norole.vue?macro=true";
import { default as newWlC0zTjz665UtmHGxRHDsOOErV5_n3OuNz_45IU1hDgBQMeta } from "/tmp/8dd6d687f2027df/pages/job/new.vue?macro=true";
import { default as settings6C1iUlDCsfkbrLbrjNvLApwuK8o6YCP9k525RczoMsYMeta } from "/tmp/8dd6d687f2027df/pages/settings.vue?macro=true";
import { default as nocompanySV55rkRv8D33I1jOB0wLb3ZgnbwPqRCQJ0RRbYlkGNcMeta } from "/tmp/8dd6d687f2027df/pages/nocompany.vue?macro=true";
import { default as _91id_935F_GOMYRk9ERdzaf6kPDlD3ErKZXV7lLQybOhl_vrWIMeta } from "/tmp/8dd6d687f2027df/pages/apply/[id].vue?macro=true";
import { default as closedNC8JyPI_45zfjtL9Cfgpc_45pmwJA1SvmsCxPnUx7_45gQxrkMeta } from "/tmp/8dd6d687f2027df/pages/job/closed.vue?macro=true";
import { default as openedNQDvM6lTPD7Pmu9rdIYHTGtoSbBVOfrSny3_sEDFPrsMeta } from "/tmp/8dd6d687f2027df/pages/job/opened.vue?macro=true";
import { default as pausedcu_03KAlp8HegCrLs8TD6qQQgsoSkmzOuPCHsY0TLuQMeta } from "/tmp/8dd6d687f2027df/pages/job/paused.vue?macro=true";
import { default as index3aKPn2sBU2T1XWuJJB6SaV0qgfzwRM6a_45H3w65iYS_cMeta } from "/tmp/8dd6d687f2027df/pages/team/index.vue?macro=true";
import { default as index1eLkrrHWacutMgjJyCjYxmJ2PAjqRuR0Iw_dWom_45AukMeta } from "/tmp/8dd6d687f2027df/pages/user/index.vue?macro=true";
import { default as index_45zYGblH4zZAUVEzAJBztBwapfSkRFCsPykcH1_452tBb4Meta } from "/tmp/8dd6d687f2027df/pages/inbox/index.vue?macro=true";
import { default as indexTlxeSrQb_61PQvCaY7smGAPeHHi9s7ovvmGO99lwH_45AMeta } from "/tmp/8dd6d687f2027df/pages/plans/index.vue?macro=true";
import { default as thanks7ScjTSUVh_45vLxqo51L5x_45qBouQQcoAM2K66Fib4xA_YMeta } from "/tmp/8dd6d687f2027df/pages/apply/thanks.vue?macro=true";
import { default as archivedIX0oNqUku9g12toOAxqgjPb3lShioi2My5k3o86lHnEMeta } from "/tmp/8dd6d687f2027df/pages/job/archived.vue?macro=true";
import { default as indexBRKgn4Nr32HtR6psJgB7_45qHVEcYKiRo48w3DWLOOkHgMeta } from "/tmp/8dd6d687f2027df/pages/thanks/index.vue?macro=true";
import { default as indexrj_451tp_45_rY9Y_45FTAbM0FiSb4UK_45ACSqwUriWoV8MnYoMeta } from "/tmp/8dd6d687f2027df/pages/company/index.vue?macro=true";
import { default as _91id_93ZkHbWyqoEpdS3J1OLeqfOwiOPQOVjfpILuAWlMYbZnIMeta } from "/tmp/8dd6d687f2027df/pages/applicant/[id].vue?macro=true";
import { default as mentionsg7hXnnP0y327bqO50U32h80EvDmr311Lez5mfhUEwZwMeta } from "/tmp/8dd6d687f2027df/pages/inbox/mentions.vue?macro=true";
import { default as index_45IUmLlICv_Z5DPBfG2tpC_454mFUcgJ0X3qW8L5_pQxT4Meta } from "/tmp/8dd6d687f2027df/pages/job/[job]/index.vue?macro=true";
import { default as _91id_93W_45_45PZcLfwsxZZMQ2LMQaz4fLOBtK9QhM5y7RNYM_455YEMeta } from "/tmp/8dd6d687f2027df/pages/apply/perfil/[id].vue?macro=true";
import { default as indexRC1UJerlSi5Oq5rebZ7jF7mi9eDuLI5wPmH_45gR8U8WcMeta } from "/tmp/8dd6d687f2027df/pages/auth/signin/index.vue?macro=true";
import { default as indexUWfXWMidQMLz52WEjJ0liQurNqexTgKkXachr3or_45B0Meta } from "/tmp/8dd6d687f2027df/pages/auth/signup/index.vue?macro=true";
import { default as jobsNTygFfIUFgU0utLz_kkL66FGmXWUP8o9aNf_4523d5Zz0Meta } from "/tmp/8dd6d687f2027df/pages/company/[id]/jobs.vue?macro=true";
import { default as onboardingeMqZ9kdQ6wMKq7Uzhe447RvcHeYVUzcqZVCwd_45DFerMMeta } from "/tmp/8dd6d687f2027df/pages/company/onboarding.vue?macro=true";
import { default as _91email_93ltcqkGSRNhkISGsENbqn5quUg3CEfE0JmPGG_SbbNiYMeta } from "/tmp/8dd6d687f2027df/pages/auth/signup/[email].vue?macro=true";
import { default as duplicatewGyUdGOfl90g6QdtF_X2I1PaKPKSMzOMz6yXxsuYlpcMeta } from "/tmp/8dd6d687f2027df/pages/job/[job]/duplicate.vue?macro=true";
import { default as forgotfnxRgahdeZqM2_zfV_45ywQyChLR3ahqX1zgtB_lU0tXMMeta } from "/tmp/8dd6d687f2027df/pages/auth/password/forgot.vue?macro=true";
import { default as _91_46_46_46slug_93TR1OEdgdgBQykLfy_45l1p1mTjnhLeXwgXq88xK51MvIcMeta } from "/tmp/8dd6d687f2027df/pages/auth/signin/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93wt_janAyM8BBV6u6xFwLt4OB9nbTDAdda0qXk5ucrasMeta } from "/tmp/8dd6d687f2027df/pages/auth/password/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93fb5Jv5A9Tg4mdkIDD7dzZD5mV2BPmDcKBB6VC7w59iUMeta } from "/tmp/8dd6d687f2027df/pages/auth/verifyemail/[...slug].vue?macro=true";
import { default as indexiPLcMKmVT7IAKGa5Teh_Ui6uoDzHgxDzfwp7vWrT1wsMeta } from "/tmp/8dd6d687f2027df/pages/job/[job]/applicants/index.vue?macro=true";
import { default as _91applicant_93Ko4rNrRxVy5zFhfNN6LXjoiBg03Y7GuXvxkODYwOAkgMeta } from "/tmp/8dd6d687f2027df/pages/job/[job]/applicants/[applicant].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexvw_LzcSJtvzvAn_45SelXzqu_kF7hXiHpQP6vheBc6EawMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/index.vue")
  },
  {
    name: "norole",
    path: "/norole",
    meta: noroleXFFF0QKBKoePbvX7dxBMX_ewdtEW0d4El9WQ_pakhhoMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/norole.vue")
  },
  {
    name: "job-new",
    path: "/job/new",
    meta: newWlC0zTjz665UtmHGxRHDsOOErV5_n3OuNz_45IU1hDgBQMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/job/new.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settings6C1iUlDCsfkbrLbrjNvLApwuK8o6YCP9k525RczoMsYMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/settings.vue")
  },
  {
    name: "nocompany",
    path: "/nocompany",
    meta: nocompanySV55rkRv8D33I1jOB0wLb3ZgnbwPqRCQJ0RRbYlkGNcMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/nocompany.vue")
  },
  {
    name: "apply-id",
    path: "/apply/:id()",
    component: () => import("/tmp/8dd6d687f2027df/pages/apply/[id].vue")
  },
  {
    name: "job-closed",
    path: "/job/closed",
    meta: closedNC8JyPI_45zfjtL9Cfgpc_45pmwJA1SvmsCxPnUx7_45gQxrkMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/job/closed.vue")
  },
  {
    name: "job-opened",
    path: "/job/opened",
    meta: openedNQDvM6lTPD7Pmu9rdIYHTGtoSbBVOfrSny3_sEDFPrsMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/job/opened.vue")
  },
  {
    name: "job-paused",
    path: "/job/paused",
    meta: pausedcu_03KAlp8HegCrLs8TD6qQQgsoSkmzOuPCHsY0TLuQMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/job/paused.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: index3aKPn2sBU2T1XWuJJB6SaV0qgfzwRM6a_45H3w65iYS_cMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/team/index.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: index1eLkrrHWacutMgjJyCjYxmJ2PAjqRuR0Iw_dWom_45AukMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/user/index.vue")
  },
  {
    name: "inbox",
    path: "/inbox",
    meta: index_45zYGblH4zZAUVEzAJBztBwapfSkRFCsPykcH1_452tBb4Meta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/inbox/index.vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: indexTlxeSrQb_61PQvCaY7smGAPeHHi9s7ovvmGO99lwH_45AMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/plans/index.vue")
  },
  {
    name: "apply-thanks",
    path: "/apply/thanks",
    component: () => import("/tmp/8dd6d687f2027df/pages/apply/thanks.vue")
  },
  {
    name: "job-archived",
    path: "/job/archived",
    meta: archivedIX0oNqUku9g12toOAxqgjPb3lShioi2My5k3o86lHnEMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/job/archived.vue")
  },
  {
    name: "thanks",
    path: "/thanks",
    meta: indexBRKgn4Nr32HtR6psJgB7_45qHVEcYKiRo48w3DWLOOkHgMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/thanks/index.vue")
  },
  {
    name: "company",
    path: "/company",
    meta: indexrj_451tp_45_rY9Y_45FTAbM0FiSb4UK_45ACSqwUriWoV8MnYoMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/company/index.vue")
  },
  {
    name: "applicant-id",
    path: "/applicant/:id()",
    meta: _91id_93ZkHbWyqoEpdS3J1OLeqfOwiOPQOVjfpILuAWlMYbZnIMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/applicant/[id].vue")
  },
  {
    name: "inbox-mentions",
    path: "/inbox/mentions",
    meta: mentionsg7hXnnP0y327bqO50U32h80EvDmr311Lez5mfhUEwZwMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/inbox/mentions.vue")
  },
  {
    name: "job-job",
    path: "/job/:job()",
    meta: index_45IUmLlICv_Z5DPBfG2tpC_454mFUcgJ0X3qW8L5_pQxT4Meta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/job/[job]/index.vue")
  },
  {
    name: "apply-perfil-id",
    path: "/apply/perfil/:id()",
    component: () => import("/tmp/8dd6d687f2027df/pages/apply/perfil/[id].vue")
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: indexRC1UJerlSi5Oq5rebZ7jF7mi9eDuLI5wPmH_45gR8U8WcMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/auth/signin/index.vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: indexUWfXWMidQMLz52WEjJ0liQurNqexTgKkXachr3or_45B0Meta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/auth/signup/index.vue")
  },
  {
    name: "company-id-jobs",
    path: "/company/:id()/jobs",
    component: () => import("/tmp/8dd6d687f2027df/pages/company/[id]/jobs.vue")
  },
  {
    name: "company-onboarding",
    path: "/company/onboarding",
    meta: onboardingeMqZ9kdQ6wMKq7Uzhe447RvcHeYVUzcqZVCwd_45DFerMMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/company/onboarding.vue")
  },
  {
    name: "auth-signup-email",
    path: "/auth/signup/:email()",
    component: () => import("/tmp/8dd6d687f2027df/pages/auth/signup/[email].vue")
  },
  {
    name: "job-job-duplicate",
    path: "/job/:job()/duplicate",
    meta: duplicatewGyUdGOfl90g6QdtF_X2I1PaKPKSMzOMz6yXxsuYlpcMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/job/[job]/duplicate.vue")
  },
  {
    name: "auth-password-forgot",
    path: "/auth/password/forgot",
    component: () => import("/tmp/8dd6d687f2027df/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-signin-slug",
    path: "/auth/signin/:slug(.*)*",
    meta: _91_46_46_46slug_93TR1OEdgdgBQykLfy_45l1p1mTjnhLeXwgXq88xK51MvIcMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/auth/signin/[...slug].vue")
  },
  {
    name: "auth-password-slug",
    path: "/auth/password/:slug(.*)*",
    component: () => import("/tmp/8dd6d687f2027df/pages/auth/password/[...slug].vue")
  },
  {
    name: "auth-verifyemail-slug",
    path: "/auth/verifyemail/:slug(.*)*",
    component: () => import("/tmp/8dd6d687f2027df/pages/auth/verifyemail/[...slug].vue")
  },
  {
    name: "job-job-applicants",
    path: "/job/:job()/applicants",
    meta: indexiPLcMKmVT7IAKGa5Teh_Ui6uoDzHgxDzfwp7vWrT1wsMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/job/[job]/applicants/index.vue")
  },
  {
    name: "job-job-applicants-applicant",
    path: "/job/:job()/applicants/:applicant()",
    meta: _91applicant_93Ko4rNrRxVy5zFhfNN6LXjoiBg03Y7GuXvxkODYwOAkgMeta || {},
    component: () => import("/tmp/8dd6d687f2027df/pages/job/[job]/applicants/[applicant].vue")
  }
]