// https://nuxt.com/docs/guide/directory-structure/app-config#app-config-file
export default defineAppConfig({
  ui: {
    notifications: {
      position: 'bottom-0 left-0'
    },
    // button: {
    //     rounded: 'rounded-full'
    // },
    primary: 'blue',
    gray: 'zinc'
  }
});
