<template>
  <NuxtLoadingIndicator />
  <div class="antialiased">
    <NuxtPage />
    <UNotifications class="dark" />
  </div>

  <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=7198353&fmt=gif" />
</template>

<script setup>
  if(localStorage.getItem('primary')){
      const appConfig = useAppConfig()
      appConfig.ui.primary = window.localStorage.getItem('primary')
  }
</script>

<style>
[contenteditable]:focus {
  outline: 0px solid transparent;
}
</style>